<template>
  <div v-if="itemsList?.length">
    <ul
      ref="mobileScroll"
      class="diamondshape-slick-mobile flex scroll-pl-4"
      :class="[listClasses]"
      :style="{ columnGap: gapX + 'px' }"
      @scroll="handleScroll"
    >
      <li
        v-for="(item, idx) in itemsList.slice(0, 15)"
        :key="item.id"
        :class="[itemClasses]"
        :data-scroll-slider-idx="idx"
        class="first:ml-4 last:mr-4"
        :style="
          slidesCount !== 'auto'
            ? {
                flex: `0 0 ${100 / Number(slidesCount)}%`,
                maxWidth: `${100 / Number(slidesCount)}%`,
              }
            : {}
        "
      >
        <slot :item="item" :idx="idx" :name="'card'"></slot>
      </li>
    </ul>
    <div v-show="!hidePagination" class="">
      <div ref="scrollBar" class="flex h-[3px] w-full bg-gray-scroll-bar">
        <div
          :style="{
            width: `${scrollBarOptions.spaceLeft}%`,
          }"
        ></div>
        <div
          class="h-full bg-gray-elements"
          :style="{
            width: `${scrollBarOptions.thumbWidth}%`,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Record<string, any>">
withDefaults(
  defineProps<{
    itemsList: T[];
    slidesCount: string | number | "auto";
    listClasses?: string;
    itemClasses?: string;
    gapX?: number;
  }>(),
  {
    gapX: 20,
    listClasses: "",
    itemClasses: "",
  },
);

const { mdAndDown } = useAppBreakpoints();
const mobileScroll = ref<HTMLElement>();
const scrollBar = ref<HTMLElement>();
const mobileScrollOptions = ref({
  scrollLeft: 0,
  scrollWidth: 0,
  clientWidth: 0,
});
const scrollBarOptions = ref({
  scrollWidth: 0,
  thumbWidth: 0,
  spaceLeft: 0,
});

const hidePagination = computed(() => {
  // if (!props.itemsList?.length) {
  //   return false;
  // }
  // return Number(props.slidesCount) >= props.itemsList.length;
  return true;
});

watch(mdAndDown, (val) => {
  if (val) {
    getElementsData();
  }
});
onMounted(() => {
  getElementsData();
});

function handleScroll(e) {
  mobileScrollOptions.value = {
    scrollLeft: e.target.scrollLeft,
    scrollWidth: e.target.scrollWidth,
    clientWidth: e.target.clientWidth,
  };
  scrollBarOptions.value.spaceLeft =
    (mobileScrollOptions.value.scrollLeft /
      mobileScrollOptions.value.scrollWidth) *
    100;
}

function getElementsData() {
  if (!mobileScroll.value || !scrollBar.value) {
    return;
  }
  mobileScrollOptions.value = {
    scrollLeft: mobileScroll.value.scrollLeft,
    scrollWidth: mobileScroll.value.scrollWidth,
    clientWidth: mobileScroll.value.clientWidth,
  };
  scrollBarOptions.value = {
    scrollWidth: scrollBar.value.scrollWidth || 0,
    thumbWidth:
      (mobileScrollOptions.value.clientWidth /
        mobileScrollOptions.value.scrollWidth) *
      100,
    spaceLeft: 0,
  };
}
function scrollTo(idx: number) {
  const scrollToEl = mobileScroll.value?.querySelector(
    `[data-scroll-slider-idx="${idx}"]`,
  );
  if (scrollToEl && mobileScroll.value) {
    const targetOffset = (scrollToEl as HTMLElement).offsetLeft;

    mobileScroll.value.scrollLeft = targetOffset;
  }
}

defineExpose({
  scrollTo,
});
</script>

<style scoped>
.diamondshape-slick-mobile {
  @apply flex snap-x snap-mandatory list-none overflow-x-scroll;
  /* padding: 0; */
  /* text-align: center; */
  /* text-transform: capitalize; */
  /* scroll-snap-type: x mandatory; */
  /* display: flex; */
  /* overflow-x: scroll; */
  transition: 0.25s left ease;
  /* scroll-padding-left: 4%; */
  /* list-style: none; */
  /* margin: 0 0 20px 0; */
}

.diamondshape-slick-mobile li {
  /* scroll-snap-align: start; */
  @apply snap-start;
}

.diamondshape-slick-mobile::-webkit-scrollbar {
  /* display: none; */
  @apply hidden;
}

.diamondshape-slick-mobile {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
